import React, { useState, useEffect, useRef } from "react";

import useDeviceDetect from "../../hooks/useDeviceDetect";
import { useOnClickOutside } from "../../hooks/onClickOutside";

import styles from "./styles.module.css";

const BaseLayout = ({ children }) => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);
  const isMobile = useDeviceDetect();

  const mobileMenuRef = useRef();

  useEffect(() => {
    setIsMobileMenuOpen(false);
  }, [isMobile]);

  useEffect(() => {
    document.querySelectorAll('a[href^="#"]').forEach((anchor) => {
      anchor.addEventListener("click", function (e) {
        e.preventDefault();

        document.querySelector(this.getAttribute("href")).scrollIntoView({
          behavior: "smooth",
        });
      });
    });
  }, []);

  useOnClickOutside(mobileMenuRef, () => setIsMobileMenuOpen(false));
  return (
    <div className={styles.container}>
      {/* Header Section */}
      <div className={styles.header}>
        <img src="/images/logo.png" className={styles.headerLogo} alt="Logo" />
        <ul className={styles.navList}>
          <li className={styles.opacityBtn}>
            <a href="#hero">Home</a>
          </li>
          <li className={styles.opacityBtn}>
            <a href="#about">About</a>
          </li>
          <li className={styles.opacityBtn}>
            <a href="#partners">Partners</a>
          </li>
          <li className={styles.opacityBtn}>
            <a href="#game_teams">Game Teams</a>
          </li>
          <li className={styles.opacityBtn}>
            <a href="#contact_form">Join</a>
          </li>
        </ul>
        <div className={styles.navSocialIcons}>
          <a href="https://t.me/web3gc" target="_blank">
            <img
              src="/images/icons/telegram.svg"
              className={styles.opacityBtn}
              alt="Social icons"
            />
          </a>
          <a href="https://twitter.com/web3gc_org" target="_blank">
            <img
              src="/images/icons/twitter.svg"
              className={styles.opacityBtn}
              alt="Social icons"
            />
          </a>
        </div>
        <div className={styles.mobileMenuWrapper}>
          <img
            src="/images/menu_icon.svg"
            className={styles.mobileMenuIcon}
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            alt="Menu Icon"
          />
          {isMobileMenuOpen && (
            <div className={styles.mobileNavSocialIcons} ref={mobileMenuRef}>
              <a href="https://t.me/web3gc" target="_blank">
                <img
                  src="/images/icons/telegram.svg"
                  className={styles.opacityBtn}
                  alt="Social icons"
                />
              </a>
              <a href="https://twitter.com/web3gc_org" target="_blank">
                <img
                  src="/images/icons/twitter.svg"
                  className={styles.opacityBtn}
                  alt="Social icons"
                />
              </a>
            </div>
          )}
        </div>
      </div>
      {/* Header Section */}

      {children}

      {/* Footer Section */}
      <div className={styles.footer}>
        <div className={styles.footerLogoRow}>
          <img src="/images/logo.png" className={styles.logo} alt="Logo" />
          <div className={styles.navSocialIcons}>
            <a href="https://t.me/web3gc" target="_blank">
              <img
                src="/images/icons/telegram.svg"
                className={styles.opacityBtn}
                alt="Social icons"
              />
            </a>
            <a href="https://twitter.com/web3gc_org" target="_blank">
              <img
                src="/images/icons/twitter.svg"
                className={styles.opacityBtn}
                alt="Social icons"
              />
            </a>
          </div>
        </div>
        <span className={styles.copyrightText}>Copyright 2023 | w3gc© </span>
      </div>
      {/* Footer Section */}
    </div>
  );
};

export default BaseLayout;
