import React from "react";

import BaseLayout from "../../Layouts/BaseLayout";

import styles from "./styles.module.css";

const LandingPage = () => {
  return (
    <BaseLayout>
      {/* Hero Section */}
      <div className={styles.heroSection} id="hero">
        <div className={styles.heroContents}>
          <h1 className={styles.heroTitle}>
            Advancing the Next
            <br />
            Wave of Breakout
            <br />
            Blockchain Games
          </h1>
          <p className={styles.heroSpan}>
            We are the Web3 Gaming Collective (w3gc)
          </p>
          <a href="#contact_form">
            <button className={styles.primaryButton}>Learn More</button>
          </a>
        </div>
      </div>
      {/* Hero Section */}

      <div className={styles.container}>
        {/* Our Mission Section */}
        <section className={styles.sectionPrimary} id="about">
          <div className={styles.sectionContentsWrapper}>
            <h1 className={styles.primaryTitle}>Our Mission</h1>
            <p className={styles.primaryText}>
              We believe the adoption of web3 will generate creative and economic opportunities for billions of people, and change the game industry forever. As things stand, Web3 games are still too complex, and developers struggle to navigate and manage the risks inherent with today’s landscape.  We have come together to simplify the challenges of discovery, distribution, and sustainable growth to make mass adoption possible.
            </p>
            <a href="#contact_form">
              <button className={styles.primaryButton}>Learn More</button>
            </a>
          </div>
          <img
            src="/images/mission.png"
            className={styles.sectionBanner}
            alt="Our Mission"
          />
        </section>
        {/* Our Mission Section */}

        {/* Our Partners Section */}
        <section className={styles.partnersSection} id="partners">
          <h1 className={styles.primaryTitle}>Our Partners</h1>
          <p className={styles.primaryText}>
            We partner with top-tier teams and thought leaders across the Web3
            Gaming ecosystem. Each of these teams play a critical role in the
            flywheel. With our combined efforts, resources, and expertise, w3gc
            will bring great games to web3.
          </p>
          <div className={styles.partnersLogosRow}>
            <a href="https://game7.io/" target="_blank"><img src="/images/GAME7.svg" alt="Logos" /></a>
            <a href="https://www.yieldguild.io/" target="_blank"><img src="/images/ygg.svg" alt="Logos" /></a>
          </div>
          <div className={styles.partnersLogosRow}>
            <a href="https://fenixgames.com/" target="_blank"><img src="/images/Fenix.svg" alt="Logos" /></a>
            <a href="https://magiceden.io/" target="_blank"><img src="/images/magic-eden.svg" alt="Logos" /></a>
          </div>
        </section>
        {/* Our Partners Section */}

        {/* Game Devs Section */}
        <section className={styles.sectionPrimary} id="game_teams">
          <img
            src="/images/game-devs.png"
            className={styles.gameDevsBanner}
            alt="Game devs"
          />
          <div className={styles.sectionContentsWrapper}>
            <h1 className={styles.primaryTitle}>For Game Devs</h1>
            <p className={styles.primaryText}>
              w3gc will provide an open forum to discuss, align, and work together to solve the challenges that prevent game developers from bringing great content to Web3. The success of w3gc will depend on insights from great game developers who are running into challenges in the complex web3 terrain. <br />
              <br />
              Topics of discussion include:
              <br />
              <br />
              <ul className={styles.gameDevsList}>
                <li>Project Financing / Funding</li>
                <li>Growth Marketing / User Acquisition</li>
                <li>Tokenized Economy Design</li>
                <li>Go-to-Marketing Planning</li>
                <li>Distribution / Player Liquidity</li>
                <li>Community</li>
              </ul>
            </p>
            <a href="#contact_form">
              <button className={styles.primaryButton}>
                Join the Discussion
              </button>
            </a>
          </div>
        </section>
        {/* Game Devs Section */}

        {/* Contact Us Section */}
        <section className={styles.sectionForm} id="contact_form">
          <div className={styles.sectionContentsWrapper}>
            <h1 className={styles.formPrimaryTitle}>
              Interested in becoming a w3gc partner?
            </h1>
            <p className={styles.formPrimaryText}>
              We are looking to partner with top tier teams in the web3 space
              who are focused on improving discovery, distribution, and
              sustainable growth for high potential, quality blockchain game
              projects.
            </p>
            <h1 className={styles.formPrimaryTitle}>
              Are you building Web3 games?
            </h1>
            <p className={styles.formPrimaryText}>
              We are looking for great game developers who are building great
              Web3 games.
            </p>
          </div>

          {/* Contact Us Form */}
          <form className={styles.contactUsForm} action="mailto:join@web3gc.org">
            <h1 className={styles.formPrimaryTitle}>Contact Us</h1>
            <div className={styles.inputItem}>
              <label>Name</label>
              <input
                type="text"
                name="name"
                placeholder="Enter your name"
                className={styles.contactUsShortInput}
              />
            </div>
            <div className={styles.inputItem}>
              <label>Company Name</label>
              <input
                type="text"
                name="companyName"
                placeholder="Enter your company name"
                className={styles.contactUsShortInput}
              />
            </div>
            <div className={styles.inputItem}>
              <label>Email</label>
              <input
                type="email"
                name="email"
                placeholder="Enter your email"
                className={styles.contactUsShortInput}
              />
            </div>
            <div className={styles.inputItem}>
              <label>Tell us about your team</label>
              <textarea
                name="team"
                placeholder="Enter your message"
                className={styles.contactUsLongInput}
              />
            </div>

            <button className={styles.primaryButton} type="submit">
              Submit
            </button>
          </form>
          {/* Contact Us Form */}
        </section>
        {/* Contact Us Section */}
      </div>
    </BaseLayout>
  );
};

export default LandingPage;
